export enum AlertStatusEnumType {
    info = 'info',
    error = 'error',
    success = 'success',
    warning = 'warning',
    loading = 'loading',
}

export enum TransferEnumType {
    mtnToMtn = 'mtn_to_mtn', 
}

export enum AccountEnumType {
    none = 'NONE',
    orangeCM = 'CM_ORANGE',
    mtnCM = 'CM_MTN',
    paypal = 'PAYPAL',
    stripe = 'STRIPE',
}